import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import Card from '../components/Card';

export default () => (
  <Layout>
    <Seo title="Contact Us" page="contact" />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto text-center px-8">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Contact Us</h1>
        <p className="text-xl lg:text-2xl mt-6 font-light">Let us know how we can help you today</p>
      </div>
    </section>
    <section className="py-20">
      <div className="container mx-auto text-center">
        <div className="flex flex-col flex-wrap sm:flex-row sm:-mx-3 mt-12">
          <div className="flex flex-1 md:flex-none md:w-1/2 px-3 mb-8">
            <Card className="text-center px-8 flex-1">
              <h3 className="text-2xl font-semibold leading-tight">Phone Number</h3>
              <a href="tel:18016631277" className="mt-4 block">
                801-663-1277
              </a>
            </Card>
          </div>
          <div className="flex flex-1 md:flex-none md:w-1/2 px-3 mb-8">
            <Card className="text-center px-8 flex-1">
              <h3 className="text-2xl font-semibold leading-tight">Email</h3>
              <a href="mailto:mike@timpaccounting.com" className="mt-4 block">
                mike@timpaccounting.com
              </a>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section className="container mx-auto mt-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Office Hours</h3>
      <p className="mt-8 text-xl font-light">
        We currently run a COVID-19 friendly office and do not offer in-person visits. We believe
        that we offer a better and more convenient experience by keeping everything online and
        allowing you to work with us from the comfort of your home.
      </p>
    </section>
  </Layout>
);
